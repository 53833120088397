<template>
    <v-container>
      <!-- FullCalendar component -->
      <FullCalendar :options="calendarOptions" />
  
      <!-- Modal for reservation details -->
      <v-dialog v-model="showModal" max-width="600">
  <v-card>
    <v-card-title class="headline text-center">Reservation Details</v-card-title>

    <v-card-text>
      <v-container>
        <!-- Reservation Information -->
        <v-row>
          <v-col cols="12" md="6">
            <p><strong>Full Name:</strong> {{ selectedReservation.full_name }}</p>
            <p><strong>Email:</strong> {{ selectedReservation.email }}</p>
            <p><strong>Phone:</strong> {{ selectedReservation.phone }}</p>
            <p><strong>Date:</strong> {{ selectedReservation.date }}</p>
          </v-col>

          <v-col cols="12" md="6">
            <p><strong>Start Time:</strong> {{ selectedReservation.start_time }}</p>
            <p><strong>End Time:</strong> {{ selectedReservation.end_time }}</p>
            <p><strong>Number of People:</strong> {{ selectedReservation.no_of_people }}</p>
          </v-col>
        </v-row>

        <!-- Table Details: Only show if a table is assigned to the reservation -->
        <v-row v-if="selectedReservation.table">
          <v-col cols="12">
            <p><strong>Table:</strong> {{ selectedReservation.table.title }}</p>
            <p><strong>Table Capacity:</strong> {{ selectedReservation.table.seating_capacity }}</p>
          </v-col>
        </v-row>

        <!-- Reservation Notes and Status -->
        <v-row>
          <v-col cols="12">
            <p><strong>Notes:</strong> {{ selectedReservation.notes || "No notes provided." }}</p>
          </v-col>

          <v-col cols="12" sm="6">
            <p>
              <strong>Status:</strong>
              <v-chip :style="{ backgroundColor: getStatusColor(selectedReservation.status) }" dark>
                {{ selectedReservation.status }}
              </v-chip>
            </p>
          </v-col>

          <v-col cols="12" sm="6">
            <p class="d-flex">
              <strong>Payment Status:</strong>
              <v-chip :color="selectedReservation.xonder_transaction_id ? 'success' : 'error'" dark>
                {{ selectedReservation.xonder_transaction_id ? "Advance Paid Online" : "Not Paid" }}
              </v-chip>
            </p>
          </v-col>
        </v-row>

        <!-- Action Buttons for Changing Status -->
        <v-row v-if="selectedReservation.status === 'Pending'">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn @click="openConfirmationDialog('Reserved')" class="mr-2 bg-primary text-light" small>
              Accept
            </v-btn>
            <v-btn @click="openConfirmationDialog('Rejected')" class="bg-danger text-light" small>
              Decline
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" text @click="showModal = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

  
      <!-- Confirmation Dialog for Status Change -->
      <v-dialog v-model="dialog.visible" max-width="400">
        <v-card>
          <v-card-title class="headline">Confirm Action</v-card-title>
          <v-card-subtitle>
            Are you sure you want to change the status to "{{ dialog.status }}"?
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="confirmChangeStatus" class="bg-primary text-light font-poppins">Yes</v-btn>
            <v-btn @click="dialog.visible = false" text>No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import FullCalendar from "@fullcalendar/vue";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import { BUSINESS_RESERVATIONS_UPDATE, BUSINESS_RESERVATIONS } from "@/core/services/store/config.module";
  
  export default {
    name: "ReservationsTableWidget",
    components: {
      FullCalendar,
    },
    // props: {
    //   reservations: Array,
    // },
    computed: {
      ...mapGetters(["currentUser"]),
      totalPages() {
        return Math.ceil(this.form.reservations.total / this.itemsPerPage);
      },
    },
    data() {
      return {
        calendarOptions: {
          plugins: [dayGridPlugin],
          initialView: "dayGridMonth",
          weekends: true,
          events: [], // Initially empty, populated based on reservations prop
          eventClick: this.onEventClick, // Directly link the method
        },
        showModal: false, // Controls the visibility of the modal
        selectedReservation: {}, // Stores the clicked reservation details
        dialog: {
          visible: false,
          status: '',
        },
        currentPage: 1,
        itemsPerPage: 10,
        loading: false,
        reservations:[]
      };
    },
    watch: {
      reservations: {
        immediate: true,
        handler(newReservations) {
          // Map reservations to FullCalendar events
          this.calendarOptions.events = newReservations.map((reservation) => ({
            id: reservation.id,
            title: reservation.title || reservation.full_name,
            start: reservation.date,
            end: reservation.end_date,
            color: this.getStatusColor(reservation.status),
          }));
        },
      },
    },
    mounted(){
      this.loadReservations();
  //       this.$nextTick(() => {
  //   this.$refs.calendar.getApi().updateSize();
  // });
    },
    methods: {
      formatTime(time) {
        const [hour, minute] = time.split(":");
        return `${hour}:${minute}`;
      },
      getStatusColor(status) {
        switch (status) {
          case "Reserved":
            return "green";
          case "Pending":
            return "orange";
          case "Rejected":
            return "red";
          default:
            return "gray";
        }
      },
      onEventClick(info) {
        const reservation = this.reservations.find((res) => res.id == info.event.id);
        if (reservation) {
          this.selectedReservation = reservation;
          this.showModal = true; // Show the modal
        }
      },
      openConfirmationDialog(status) {
        this.dialog.visible = true;
        this.dialog.status = status;
      },
      confirmChangeStatus() {
        const { id } = this.selectedReservation;
        const { status } = this.dialog;
        this.dialog.visible = false;
  
        // Call API to update status
        this.$store
          .dispatch(BUSINESS_RESERVATIONS_UPDATE, { id, status })
          .then(() => {
            // After status change, reload the data and update the calendar
            this.loadReservations();
               this.showModal = false; // Show the modal
          })
          .catch(console.error);
      },
      loadReservations() {
        this.loading = true;
        this.$store
          .dispatch(BUSINESS_RESERVATIONS, { page: this.currentPage, per_page: this.itemsPerPage,withoutpagination:1 })
          .then((response) => {
            this.reservations = response;
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
          });
      },
    },
  };
  </script>

  
  <style scoped>
  .fc-daygrid-event-harness{
    cursor: pointer;
  }
.status-chip {
  color: white;
  font-weight: bold;
}

.status-reserved {
  background-color: green;
}

.status-pending {
  background-color: orange;
}

.status-rejected {
  background-color: red;
}
  </style>
  